import React, { Component } from "react"
import { ParallaxProvider } from "react-scroll-parallax"
import { Parallax } from "react-scroll-parallax"
import SEO from "../components/seo"
import SquareOwl from "../svgs/SquareOwl.svg"
import Arrows from "../svgs/Arrows.svg"
import BoxArrow from "../svgs/BoxArrow.svg"
import Circle1 from "../svgs/Circle1.svg"
import Circle2 from "../svgs/Circle2.svg"
import Circle3 from "../svgs/Circle3.svg"
import Circle4 from "../svgs/Circle4.svg"
import Circle5 from "../svgs/Circle5.svg"
import Circle6 from "../svgs/Circle6.svg"
import Circle7 from "../svgs/Circle7.svg"
import CircleArrows from "../svgs/CircleArrows.svg"
import Cup from "../svgs/Cup.svg"
import Diamond from "../svgs/Diamond.svg"
import Doc from "../svgs/Doc.svg"
import Hand from "../svgs/Hand.svg"
import Lock from "../svgs/Lock.svg"
import Map from "../svgs/Map.svg"
import Refresh from "../svgs/Refresh.svg"
import Star from "../svgs/Star.svg"
import Symbol1 from "../svgs/Symbol1.svg"
import Symbol2 from "../svgs/Symbol2.svg"
import Symbol3 from "../svgs/Symbol3.svg"

class About extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMap: false,
    }
  }

  componentDidMount() {
    this.setState({ showMap: true })
  }

  componentWillUnmount() {
    this.setState({ showMap: false })
  }

  render() {
    const pageContent = (
      <>
        <section className="about-section-one">
          <div className="u-grid">
            <h1>
              Digital People who care as much about your success as you{" "}
              <span>.</span>
            </h1>
            <div className="about-section-one__text">
              <p>
                We are fascinated about the work our clients do and thrive off
                the minutia of their worlds. Unlike a traditional digital
                agency, we don’t sell you a service – we work together.
              </p>
              <p>
                Tell us your aspirations and we’ll work with you to create and
                execute a fully scoped strategic plan for digital revenue
                generation. Whether that focuses on your websites, conversion
                rate, or a broader strategic direction.
              </p>
              <p>
                <strong>
                  Our goal is to set your data free and utilise it to achieve
                  maximum return on investment.
                </strong>
              </p>
              <Parallax
                className="about-section-one__symbol1 u-symbol"
                y={[-100, 10]}
                tagOuter="figure"
              >
                <Circle1 />
              </Parallax>
              <Circle2 className="about-section-one__symbol2 u-symbol" />
              <Parallax
                className="about-section-one__symbol3 u-symbol"
                y={[20, -50]}
                tagOuter="figure"
              >
                <Circle3 />
              </Parallax>
              <Parallax
                className="about-section-one__symbol4 u-symbol"
                y={[0, -30]}
                tagOuter="figure"
              >
                <Symbol1 />
              </Parallax>
              <Parallax
                className="about-section-one__symbol5 u-symbol"
                y={[-10, 40]}
                tagOuter="figure"
              >
                <Symbol2 />
              </Parallax>
            </div>
          </div>
        </section>
        <section className="about-section-two u-bg-white u-color-blue">
          <div className="u-grid">
            <h2>Here are some great reasons to work with our team:</h2>
            <ul>
              <div>
                <li>
                  <Cup />
                  <h3>We are always pushing to be the best at what we do</h3>
                  <p>
                    Our unique operating model and network of digital
                    specialists enables us to tap into a rich source of
                    expertise across four key pillars – data analysis, digital
                    optimisation, strategic consulting and product management.
                    Constantly building on our knowledge to add value to our
                    clients.
                  </p>
                </li>
                <li>
                  <BoxArrow />
                  <h3>We thrive in regulated markets and under constraint</h3>
                  <p>
                    After 25 years working in the digital consulting industry we
                    understand clients must work within the limitations of their
                    systems and often have regulatory trade offs, such as in
                    Financial services. Taking a rational approach to these
                    blockers, we’ve helped clients balance their needs with
                    compliance, achieving with real-world results.
                  </p>
                </li>
              </div>
              <div>
                <li>
                  <Star />
                  <h3>We make customers proud to use you</h3>
                  <p>
                    Our certifications from Goldman Sachs, Human Factors
                    International and Neilson Norman Group, show how seriously
                    we take customer-centricity. Whether we are looking for
                    opportunities in your data, creating the next high
                    converting a/b test or mapping out your company’s digital
                    direction.
                  </p>
                </li>
                <li>
                  <Diamond />
                  <h3>We’re small, but perfectly formed</h3>
                  <p>
                    Our collaborative approach with you, your existing internal
                    teams and any existing agency’s, enables us to punch well
                    above our weight. Our lean and agile approach means we can
                    achieve the success you desire at a cost that will make you
                    smile.
                  </p>
                </li>
                <li>
                  <Arrows />
                  <h3>You’ll love our hyper flexible pricing structure</h3>
                  <p>
                    Work with us and you’ll benefit from fair and realistic
                    pricing and you won’t have to sign a contract. We’ve never
                    needed them, our 98% client retention rate speaks for
                    itself.
                  </p>
                </li>
              </div>
            </ul>
            <Parallax
              className="about-section-two__symbol1 u-symbol"
              y={[20, -50]}
              tagOuter="figure"
            >
              <Circle5 />
            </Parallax>
            <Parallax
              className="about-section-two__symbol2 u-symbol"
              y={[10, -80]}
              tagOuter="figure"
            >
              <Circle4 />
            </Parallax>
            <Parallax
              className="about-section-two__symbol3 u-symbol"
              y={[-10, 40]}
              tagOuter="figure"
            >
              <Circle1 />
            </Parallax>
            <Parallax
              className="about-section-two__symbol4 u-symbol"
              y={[10, -80]}
              tagOuter="figure"
            >
              <Symbol3 />
            </Parallax>
            <Parallax
              className="about-section-two__symbol5 u-symbol"
              y={[20, -50]}
              tagOuter="figure"
            >
              <Circle6 />
              <Circle6 />
              <Circle7 />
            </Parallax>
            <Parallax
              className="about-section-two__symbol6 u-symbol"
              y={[20, -50]}
              tagOuter="figure"
            >
              <Circle5 />
            </Parallax>
            <Parallax
              className="about-section-two__symbol7 u-symbol"
              y={[20, -50]}
              tagOuter="figure"
            >
              <Circle5 />
            </Parallax>
          </div>
        </section>
        <section className="about-section-three u-bg-green">
          <div className="u-grid">
            <div className="about-section-three__intro">
              <h2>What we do in detail</h2>
              <p>
                At Square Owl, we don’t just offer the traditional digital
                agency services, we focus around{" "}
                <strong>
                  digital strategic consulting, product management, digital
                  analytics and conversion rate optimisation
                </strong>
                , but what does that actually mean?{" "}
              </p>
            </div>
            <div className="about-section-three__lists">
              <div>
                <h3>Analysis & Data Services</h3>
                <ul>
                  <li>Data gathering & analysis</li>
                  <li>Data Visualisation</li>
                  <li>Analytics Development</li>
                  <li>Complex data gathering</li>
                  <li>Complex digital analytics setup</li>
                  <li>Traffic analysis & reporting</li>
                  <li>Complex funnel management</li>
                  <li>Attribution scoring</li>
                  <li>Affiliate tracking</li>
                  <li>Data interpretation</li>
                </ul>
              </div>
              <div>
                <h3>Strategic Consulting</h3>
                <ul>
                  <li>Digital Strategy development</li>
                  <li>Digital Strategy workshops</li>
                  <li>Goldman Sachs-certified Strategy creation</li>
                  <li>SOSTAC Strategy</li>
                  <li>KPI management</li>
                  <li>Strategy outlining</li>
                  <li>Strategic branding</li>
                  <li>Marketing Automation</li>
                  <li>Technical & Infrastructure consultancy</li>
                  <li>Digital Consultation</li>
                  <li>Persona research</li>
                </ul>
              </div>
              <div>
                <h3>Product Management</h3>
                <ul>
                  <li>Specification</li>
                  <li>Product design</li>
                  <li>Product roadmapping</li>
                  <li>Feature Evaluation</li>
                  <li>Prototyping</li>
                </ul>
              </div>
              <div>
                <h3>Digital Optimisation</h3>
                <ul>
                  <li>Conversion rate optimisation</li>
                  <li>User experience optimisation</li>
                  <li>Customer journey mapping</li>
                  <li>Process flow mapping</li>
                  <li>User Testing</li>
                  <li>Focus group research</li>
                  <li>A/B Testing</li>
                  <li>Multi variant testing</li>
                  <li>Data lead optimisation</li>
                  <li>Optimisation strategy</li>
                  <li>Ecommerce optimisation</li>
                  <li>NPR Scoring</li>
                  <li>Channel Optimisation</li>
                  <li>Heuristic product review</li>
                  <li>PET Design</li>
                  <li>Process optimisation</li>
                  <li>Prototyping</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="about-section-four u-bg-white u-color-blue">
          <div className="u-grid">
            <h2>Our promise to you</h2>
            <p>
              We champion business growth through data, strategy and
              optimisation. We want to show you what data excellence looks like
              for your company, to help transform your business, deliver new
              efficiencies, and help you discover new revenue streams, products,
              customers and markets. Here is our six-point promise to you:
            </p>
            <h3>The Square Owl team will….</h3>
            <ul className="c-cards">
              <li>
                <Lock />
                <p>
                  Reveal hidden opportunities to unlock value in your digital
                  channels using data
                </p>
              </li>
              <li>
                <Doc />
                <p>
                  Transform this vision into a fully specified technical brief,
                  outlining the steps necessary for success
                </p>
              </li>
              <li>
                <Hand />
                <p>
                  Hand-pick, brief and manage the experts your project requires
                  from our carefully curated network
                </p>
              </li>
              <li>
                <Map />
                <p>
                  Deliver a bespoke end-to-end service, tailored to your needs,
                  budget and deadline
                </p>
              </li>
              <li>
                <Refresh />
                <p>
                  Remain fully transparent, accountable and vocal throughout,
                  offering regular updates in person and via the project
                  management tools of your choice
                </p>
              </li>
              <li>
                <CircleArrows />
                <p>
                  Collaborate with passion and professionalism, fully
                  integrating into your team.
                </p>
              </li>
            </ul>
          </div>
        </section>
        <section className="about-section-five">
          <div className="u-grid">
            <div className="about-section-five__grid">
              <div className="about-section-five__logo">
                <SquareOwl />
                <h2>
                  <span>Why are</span> you called <span>Square Owl?</span>
                </h2>
              </div>
              <div className="about-section-five__info">
                <p>
                  It sort of just stuck and we think it sounds nice. Regardless,
                  we don’t want to talk about ourselves, we want to talk about
                  you. Give us a quick call, shoot us an email or stop by for a
                  coffee on us.{" "}
                </p>
                <p>
                  <span>Duncan Woodward, Managing Director</span> <br />
                  Email:{" "}
                  <a href="mailto:duncan@squareowl.co.uk">
                    duncan@squareowl.co.uk
                  </a>
                </p>
                <p>
                  Enquires:{" "}
                  <a href="mailto:hello@squareowl.co.uk">
                    hello@squareowl.co.uk
                  </a>{" "}
                  <br />
                  Telephone: <a href="tel:0161 883 7010">0161 883 7010</a>
                  <br />
                  Linkedin:{" "}
                  <a href="https://www.linkedin.com/company/square-owl">
                    Square Owl
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
      </>
    )

    return (
      <main>
        <SEO
          title="About us"
          description="Digital People who care as much about your success as you"
          pageClass="s-about"
        ></SEO>
        <ParallaxProvider>{pageContent}</ParallaxProvider>
      </main>
    )
  }
}

export default About
